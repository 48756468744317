/* Large Desktop ViewCARDIO Dashboard 2024 */
@mixin large-desktop {
  @media (min-width: 2560px) {
    @content;
  }
}

/* Full Desktop ViewCARDIO Dashboard 2024 */
@mixin full-desktop {
  @media (min-width: 1920px) and (max-width: 2559px) {
    @content;
  }
}

/* HD Desktop ViewCARDIO Dashboard 2024 */
@mixin hd-desktop {
  @media (min-width: 1366px) and (max-width: 1919px) {
    @content;
  }
}

/* Smaller Desktop ViewCARDIO Dashboard 2024 */
@mixin smaller-desktop {
  @media (min-width: 1280px) and (max-width: 1365px) {
    @content;
  }
}

/* Mid-Range ViewCARDIO Dashboard 2024 */
@mixin mid-range {
  @media (min-width: 1025px) and (max-width: 1279px) {
    @content;
  }
}

/* Tablet ViewCARDIO Dashboard 2024 */
@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

/* Mobile ViewCARDIO Dashboard 2024 */
@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin smaller-desktop-or-tablet {
  @media (min-width: 1280px) and (max-width: 1365px), (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
