@use "sass:math";

.grid {
  display: grid;
  grid-gap: 1.5rem;
}

.grid-gap-s {
  grid-gap: 5px;
}

@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }
}

@for $i from 1 through 6 {
  .grid-rows-#{$i} {
    grid-template-rows: repeat($i, minmax(0, 1fr));
  }
}

@for $i from 1 through 12 {
  .col-span-#{$i} {
    grid-column: span $i / span $i;
  }
}

@for $i from 1 through 6 {
  .row-span-#{$i} {
    grid-row: span $i / span $i;
  }
}
