.mat-mdc-dialog-surface {
  background-color: #fff !important;
  max-height: 100vh !important;
}

[mat-dialog-title] {
  font-size: 1.5rem !important;
  font-weight: 300 !important;
  margin: 0 !important;
  padding: 1.5rem;
}

[mat-dialog-content] {
  padding: 0 !important;
 
}

.mdc-dialog__content {
  overflow-y: hidden !important;
}

.mdc-dialog__container {
  background-color: #fff !important;
}