.mat-mdc-card {
  background-color: var(--card-bg) !important;
  color: var(--card-bg-contrast) !important;
  border-radius: 16px !important;
  border: var(--card-border) !important;
  box-shadow: none !important;
}

.mat-mdc-card-image {
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.mat-mdc-card-header {
  position: relative;
  padding: 1.5rem !important;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(100% - 3rem);
    transform: translateX(-50%);
    border-bottom: 2px solid #dfdfdf;
  }
}

.mat-mdc-card-title-group {
  align-items: center;
}

.mat-mdc-card-title {
  font-size: 1.5rem !important;
  font-weight: 300 !important;
}

.mat-mdc-card-avatar {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-bottom: 0 !important;
}

.mat-mdc-card-content {
  padding: 1.5rem !important;

  p {
    font-size: 1rem;
  }
}
