@import 'media_queries.scss';

@media(max-width: 820px) {
	app-sidebar {
		display: none !important;
	}

	.module-header {
		padding: 5px 20px !important;
		flex-direction: column;
		height: auto !important;

		&-title::after {
			position: absolute;
			right: 20px;
		}
	}

	app-dashboard {

    @include tablet {
      padding-top: 70px;
      padding-left: 30px;
      padding-right: 55px;
      display: block;
    }
	}
}

@media(max-width: 760px) {

	app-dashboard {
		padding-top: 0;
	}

	header {
		>span {
			font-size: 1rem !important;
			margin-top: 1.1rem;

			>.super {
				display: none;
			}
		}
	}

	main {
		margin-top: 70px !important;
		padding: 0 !important;
	}

	.module-header {
		flex-direction: row;

		&-title::after {
			display: none !important;
		}
	}

	.module-header-actions {
		display: none !important;
	}

	.header-select-field {
    position: absolute;
    margin-right: unset !important;
    bottom: 20px;
    left: 110px;
	}
}
