@import 'typography';

.mat-mdc-form-field.mat-form-field-appearance-fill {
  outline: 1px solid var(--mat-form-field-border-color);
  border-radius: 6px;
}

.mat-mdc-form-field.mat-form-field-appearance-outline {
  .mdc-notched-outline__leading {
    border-color: var(--mat-form-field-border-color) !important;
  }

  .mdc-notched-outline__notch {
    border-color: var(--mat-form-field-border-color) !important;
  }

  .mdc-notched-outline__trailing {
    border-color: var(--mat-form-field-border-color) !important;
  }

  .mat-mdc-input-element {
    color: var(--mat-form-field-color) !important;
  }
}

.mat-mdc-input-element::placeholder,
.mat-mdc-select-placeholder,
.mat-mdc-select-arrow {
  color: var(--mat-form-field-color) !important;
}

.mat-mdc-text-field-wrapper {
  background-color: var(--mat-form-field-background-color);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 13px;
}

.mat-mdc-form-field-text-prefix > p {
  margin: 13px 0;
  color: var(--mat-form-field-color) !important;
}

.mdc-text-field {
  border-radius: 0.375rem !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff;
}

.mdc-line-ripple {
  display: none;
}

.mat-datepicker-toggle {
  background-color: var(--card-tab-background-color);

  .mat-mdc-button-base {
    border-radius: 0;
    background-color: var(--card-tab-background-color);
    color: var(--primary-blue-color);

    .mat-mdc-button-persistent-ripple {
      border-radius: 0;
    }
  }
}

.radio-group-row {
  align-items: center;

  .mat-radio-button {
    flex: 1;
    text-align: center;
    max-height: 48px !important;
  }
}

.stacked-radio-button {
  display: flex;
  flex-direction: column;

  .mat-mdc-radio-button {
    min-height: 10px !important;
  }

  .mat-mdc-radio-button .mdc-radio {
    padding: 1px 5px;
  }

  .mat-mdc-radio-touch-target,
  .mdc-radio__native-control {
    height: 18px;
    width: 18px;
  }
}

.mat-mdc-radio-button.mat-accent {
  --mat-radio-checked-ripple-color: var(--accent-color) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: none !important;
}

.radio-input {
  display: flex;
  align-items: center;

  & > mat-form-field {
    width: 168px !important;
    max-height: 48px !important;
  }

  & > mat-radio-button {
    margin-left: auto;
  }
}


.label {
  font-size: $font-size-form-label !important;
  font-weight: $font-weight-normal;
  color: var(--label-color);
}


.default-status {
  color: var(--default-status-color);
}

.active-status {
  color: var(--active-status-color);
}

.draft-status {
  fill: var(--draft-status-color) !important;
  color: var(--draft-status-color) !important;
}

.completed-status {
  color: var(--completed-status-color);
}

.uppercase {
  text-transform: uppercase !important;
}

.camelcase {
  text-transform: capitalize !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.justify-radio-group {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.custom-suffix-width {
  max-width: 150px !important;
}

.input-label {
  font-size: $font-size-small !important;
}

.mat-mdc-form-field-flex {
  align-items: unset !important;
}

.mat-mdc-radio-button {
  min-height: 48px !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: var(--sidebar-hover-icon-background) !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--sidebar-hover-icon-background) !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border-color: var(--sidebar-hover-icon-background) !important;
  background-color: var(--sidebar-hover-icon-background) !important;
}

.mat-form-field-compact {
  max-height: 48px !important;
  width: 165px !important;
  mat-form-field-compact .mat-datepicker-toggle .mat-mdc-button-base {
    background-color: #ebeff2 !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
      .mdc-text-field--textarea
    )
    .mat-mdc-form-field-infix {
    padding: unset !important;
  }

  &.number-width {
    width: 125px !important;

    span {
      padding-left: 8px;
    }
  }

  &.full-width {
    width: 99% !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea)
    .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: unset !important;
    display: flex;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
      .mdc-text-field--textarea
    )
    .mat-mdc-form-field-infix {
    padding: 5px 0 5px 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-mdc-form-field-infix {
    min-height: 46px !important;
  }
}

.mat-mdc-option {
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-bottom: 1px solid var(--mat-form-field-border-color) !important;
}

div.mat-mdc-select-panel {
  padding: 0 !important;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  height: 36px;
}

.mat-mdc-chip {
  border-radius: 18px !important;
  border: 1px solid #dadfec !important;
  color: #000 !important;
  background-color: #dadfec33 !important;
}

.form-field-container {
  position: relative;
  padding-top: 10px;
  max-width: 95%;

  &.no-pad {
    padding: unset !important;
  }

  &.margin-left {
    margin-left: 2px;
  }
}

mat-error {
  position: absolute;
  bottom: -20px;
}

.invalid {
  outline: 1px solid red !important;
}

.form-invalid {
  color: var(--mdc-theme-error, #f44336);
  font-size: 12px;
}

.mat-mdc-form-field-bottom-align::before {
  display: unset !important;
}

// .form-desc-text {
//   margin: unset !important;
//   font-size: $font-size-small !important;
//   line-height: 20px !important;
//   font-weight: 400 !important;
//   letter-spacing: 0.0178571429em !important;
//   padding-left: 10px !important;
// }

.custom-search {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.desc-text {
  font-size: 13px;
  color: #4f4f4f;
}

.form-a {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1rem;
}

.mat-mdc-standard-chip {
  --mdc-chip-with-icon-icon-size: 24px !important;
  --mdc-chip-elevated-unselected-container-color: #dadfec !important;
  width: 325px !important;
  height: 36px !important;
}

.cta-button {
  height: 49px;
  width: 100%;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 500;

  &.module-header-btn {
    width: 20%;
  }

  &.primary {
    background: var(--primary-cta-color) 0% 0% no-repeat padding-box;
    color: var(--header-font-color);
  }

  &.secondary {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 2px solid var(--unnamed-color-0d41e1);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #0d41e1;
    color: var(--sidebar-hover-background);
  }

  &:hover {
    border: 4px solid var(--unnamed-color-09c6f9);
    background: #0a30a6 0% 0% no-repeat padding-box;
    border: 4px solid #09c6f9;
    color: #ffffff;
  }
}
.chip-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.chip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-base;
  border-radius: 18px;
  background-color: #dadfec33;
  border: 1px solid #dadfec;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease;
  width: 45%;
  height: 36px;

  span {
    margin-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.chip.active {
  background-color: var(--primary-blue-color);
  border: 0;
  color: white;
}

.rounded-dialog-container {
  border-radius: 24px !important;
}
