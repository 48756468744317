@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;800&display=swap');
/**** Material ****/
@import 'scss/material/form-field.scss';
@import 'scss/material/card.scss';
@import 'scss/material/button.scss';
@import 'scss/material/stepper.scss';
@import 'scss/material/dialog.scss';
@import 'scss/material/typography.scss';

/**** Other imports ****/
@import 'scss/grid.scss';
@import 'scss/utils.scss';
@import 'scss/emoji.scss';
@import 'scss/mobile.scss';
@import 'scss/media_queries.scss';

$secondary-fixed-color: #ffffff;
$accent-fixed-color: #0aaef4;

.light {
  /* APPLICATION FRAMEWORK COLORS */
  --typography: #272d36;
  --background: #dfe3e5;
  --inactive: #ebeff2;
  --surface: #ffffff;
  --: #303641;
  --: #d9d9d9;

  --background-color: #dfe3e5;
  --header-background-color: #{$accent-fixed-color};
  --header-font-color: #fff;
  --sidebar-background-color: #{$secondary-fixed-color};
  --sidebar-font-color: #0d41e1;
  --sidebar-hover-font-color: #191a1c;
  --sidebar-hover-background: #0d41e1;
  --sidebar-hover-icon-background: #09c6f9;

  --placeholder-color: rgba(0, 0, 0, 0.5);
  --disabled-color: rgba(0, 0, 0, 0.38);
  --file-upload-placeholder: #ebeff2;
  --file-upload-border: #d9d9d9;

  --primary-color: #385f7a;
  --primary-blue-color: #0a30a6;
  --primary-cta-color: #0d41e1;
  --secondary-color: #f50057;
  --accent-color: #0a30a6;

  --primary-contrast-color: #fff;
  --main-text-color: #0a30a6;

  --support-background-color: #{$accent-fixed-color};
  --support-font-color: #fff;

  --card-bg: #ffffff;
  --card-bg-contrast: #272d36;
  --card-tab-background-color: #{$secondary-fixed-color};
  --card-tab-font-color: #000;
  --card-border: 1px solid #d9d9d9;
  --card-body-background-color: #fff;
  --card-tab-hover-background-color: #{$accent-fixed-color};
  --card-tab-active-font-color: #000;

  --mat-form-field-background-color: #dadfec33;
  --mat-form-field-color: #272d36;
  --mat-form-field-border-color: #d9d9d9;
  --mat-form-field-placeholder-color: #272d36;
  --label-color: #272d36;
  --default-status-color: #272d3680;
  --active-status-color: #0d41e1;
  --draft-status-color: #ffd100;
  --completed-status-color: #18aa61;
  --mat-radio-checked-ripple-color: var(--background) !important;
  --table-bg: #f5f7f8;
}

.dark {
  --background-color: #272d36;
  --header-background-color: #272d36;
  --header-font-color: #fff;
  --sidebar-background-color: #303641;
  --sidebar-font-color: #ebeff2;
  --sidebar-hover-font-color: #191a1c;
  --placeholder-color: rgba(255, 255, 255, 0.5);
  --disabled-color: rgba(255, 255, 255, 0.38);
  --file-upload-placeholder: #14100d;
  --file-upload-border: #262626;

  --primary-color: #4d7c9d;
  --secondary-color: #f50057;

  --primary-contrast-color: #fff;
  --main-text-color: #ffffff;

  --support-background-color: #ebeff2;
  --support-font-color: #000;

  --card-bg: #303641;
  --card-bg-contrast: #ebeff2;
  --card-tab-background-color: #ebeff2;
  --card-tab-font-color: #000;
  --card-body-background-color: #303641;
  --card-tab-hover-background-color: #{$accent-fixed-color};
  --card-tab-active-font-color: #000;

  --table-bg: #2c323b;
  --mat-radio-checked-ripple-color: var(--background);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.mat-typography *:not(.mat-icon) {
  font-family: 'Outfit', sans-serif !important;
}

html {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  font-size: $font-size-base;
  overflow-y: hidden;
}

html,
body {
  height: 100%;

  @media (max-width: 820px) {
    height: 100vh;
    overflow: auto;
  }
}

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  color: var(--main-text-color);
  background: linear-gradient(
    125deg,
    #1e079f 25%,
    #2c0daa 25%,
    #2039cc 55%,
    #2a49d4 55%,
    #2a4ad5 65%,
    #2259df 65%,
    #0b5ce4 75%,
    #07c8f9 100%
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
  margin: 0 !important;
}

h1 {
  font-size: $font-size-xxl !important;
}

h3 {
  font-size: $font-size-large !important;
}

a {
  color: var(--sidebar-font-color);
  cursor: pointer;
}

img {
  width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

.module-header {
  display: flex;
  position: absolute;
  top: 80px;
  left: 0px;
  width: 100%;
  padding: 10px 30px 10px 220px;
  background: linear-gradient(
    125deg,
    #fab932 29.5%,
    #fbbd32 29.5%,
    #fde185 69.5%,
    #fed14a 69.5%,
    #ffdc5d 83%,
    #fed13d 83%,
    #fed13d 89%,
    #ffd33e 100%
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 81px;
  z-index: 100;

  @include smaller-desktop-or-tablet {
    height: 64px;
    top: 56px;
  }

  &-content {
    display: flex;
    height: 100%;
    flex: 1;
  }

  &-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;

    > h1 {
      font-size: 36px;
      line-height: 3.8rem;
      margin: 0;
      text-transform: uppercase;
      white-space: nowrap;

      @include smaller-desktop {
        padding-left: 10px;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 107px;
      height: 72px;
      background-image: url('assets/images/tristars.svg');
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 10px;
      opacity: 0.4;
    }

    &.small-right {
      &::after {
        margin-left: -60px;
      }
    }
  }

  &-filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
  }

  .header-select-field {
    display: flex;
    flex-direction: column;
    max-width: 400px;

    &.compact {
      max-width: 130px;
      width: 175px;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    gap: 2.5rem;
    margin-right: 2rem;

    &-button {
      font-size: $font-size-small;
      background-color: var(--sidebar-font-color);
      color: var(--primary-contrast-color);
      border: none;
      border-radius: 25px;
      transition: 0.2s ease-in-out;
      min-width: 170px;
      height: 49px;
      font-size: 18px;
      padding: 0 16px;

      &:hover {
        background-color: #fff;
        color: var(--sidebar-font-color);
      }
    }
  }
}

.cancel-btn {
  background: none;
  border: none;
  color: #4f4f4f;
  font-size: 18px;
  text-decoration: underline;
  &-footer {
    position: relative;
    z-index: 2;
  }
  &:hover {
    font-weight: 600;
  }
}

.split-screen {
  background: transparent linear-gradient(180deg, #65d7f7 55%, #ffd43e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 16px;
  min-height: 418px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 30px;
  position: relative;
}

.split-image {
  position: relative;
  z-index: 1;
  margin-top: -24px;
}

.module-content {
  background: transparent linear-gradient(180deg, #65d7f7 88%, #ffd43e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 16px;
  padding: 10px;
  min-height: 65vh;
  position: relative;
  z-index: 0;
  padding-bottom: 74px;
  margin-bottom: 30px;

  &::before {
    content: '';
    background-color: rgba(255, 255, 255, 0.5);
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    border-radius: 16px;
    z-index: -1;
  }
}

.chips {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
  max-height: 100vh;

  .chip-button {
    background-color: var(--header-font-color);
    border-radius: 18px;
    padding: 10px 10px 10px 20px;
    width: calc(100% - 40px);
    transition: width 0.3s ease-out;
    display: flex;
    border: 1px solid var(--header-font-color);
    position: relative;
    flex: 1;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
    }

    .promo-status {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      width: 80px;
      padding: 5px 10px;
      border-radius: 20px;
      background-color: #ccc;
      color: #fff;
      font-size: $font-size-xxs;
      font-weight: 500;
      text-transform: uppercase;
      margin-right: 5px;
      margin-left: 10px;
    }

    img {
      display: none;
      position: absolute;
      height: 1rem;
      width: 2rem;
      right: 8px;
    }

    &:hover {
      background-color: var(--primary-blue-color);
      color: var(--primary-contrast-color);
      width: 100%;

      img {
        display: flex;
      }
    }

    &.active {
      width: 290px;
      background-color: var(--primary-blue-color);
      color: var(--primary-contrast-color);
      width: 100%;

      img {
        display: flex;
      }
    }
  }
}

.title-section {
  background-color: #e3f7fe;
  padding: 10px;
  margin-bottom: 0;
  margin-top: 20px;
  position: relative;
  height: 47px;
  pointer-events: auto;
  justify-content: flex-start;
  width: 100%;
  top: 0;

  .title-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .title-text--split {
    padding-left: 20px;
  }

  h1 {
    color: rgb(9, 9, 9) !important;
    font-weight: 300 !important;
    font-size: $font-size-xl !important;
    letter-spacing: 0px !important;
  }

  .records-count {
    font-size: $font-size-xxs;
    color: #000 !important;
    font-weight: 300;
  }

  .title-section-right {
    display: flex;
    align-items: center;
    margin-left: 20px;
    pointer-events: auto;

    .graphics {
      width: 296px;
      height: 65px;
      background-image: url('assets/images/placeholder.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      position: absolute;
      right: 90px;
      top: 50%;
      transform: translateY(-50%);

      &[class*='customers'] {
        background-image: url('assets/images/carssettings.svg');
      }
    }

    .export-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);

      > button {
        width: 60px;
        height: 55px;
        border: 0;
        border-radius: 28px 0px 0px 28px;
        background: var(--accent-color) 0% 0% no-repeat padding-box;
        color: var(--primary-contrast-color);

        &:hover {
          background-color: #0d41e1;
        }

        &:focus {
          background-color: #0a30a6;
        }

        > span {
          font-size: $font-size-small;
          font-weight: 600;
          margin-left: 10px;
          color: var(--primary-contrast-color);
        }
      }
    }

    .export-button--right {
      right: 0;
    }
  }
}

.footer-section {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;

  .pagination-left {
    display: flex;

    > span {
      padding: 5px;
    }

    .export-button-bottom {
      display: flex;
      justify-content: center;
      position: relative;
      padding-left: 25px;
      margin-bottom: -10px;

      > button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 56px;
        height: 55px;
        border: 0;
        border-radius: 28px 28px 0px 0;
        background: var(--accent-color) 0% 0% no-repeat padding-box;
        color: var(--primary-contrast-color);
        padding: 9.5px 7.5px;

        > span {
          font-size: $font-size-small;
          font-weight: 600;
          color: var(--primary-contrast-color);
        }
      }
    }
  }

  .pagination-right {
    display: flex;
    align-items: center;
    padding-right: 2.5rem;

    > span {
      padding: 5px;
    }

    button {
      background: none;
      border: none;

      &.active {
        font-weight: bold;
      }
    }
  }
}

.card {
  &-footer-button {
    font-size: $font-size-large;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-cta-color);
    color: var(--primary-contrast-color);
    border: none;
    border-radius: 25px;
    font-weight: 500;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #4f4e4e;
    }
  }
}

.w-full {
  width: 99%;
}

.h-full {
  height: 100%;
}

.h-mid {
  height: 50vh;
}

.field-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 28px;
}

.field-container {
  display: flex;
  align-items: center;
  padding: 13px 26px;
  border-radius: 25px;
  gap: 0.75rem;

  label {
    font-size: 1.125rem;
    color: #fff;
    font-weight: 500;

    &.dark {
      color: #272d36;
    }
  }
}

.stepper-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: auto;

  .date {
    flex: 1 1 0;
    font-size: 0.75rem;
    color: var(--sidebar-font-color);
  }

  .mat-mdc-button {
    color: var(--sidebar-font-color);
  }
}

.mat-toolbar.b-b {
  border-bottom: 0px solid #d9d9d9;
  height: 10px;
}

.stepper-hidden {
  .mat-step-icon.mat-step-icon-state-number,
  .mat-step-icon.mat-step-icon-state-edit,
  .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:last-child)::after,
  .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:first-child)::before {
    display: none !important;
  }

  .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    border-width: 0;
  }
}

.heartbeat-divider {
  position: relative;
  width: calc(100% + 3.1rem);
  height: 88px;
  background-image: url('assets/images/heartbeat-divider-v2.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;

  background-position: center;
  margin-left: -1.5rem;
  margin-right: -1rem;
}

.heartbeat-divider-inform {
  background-image: url('assets/images/heartbeat-divider-v1.svg');
  background-repeat: no-repeat;
}

.form-bottom {
  position: relative;
  width: 100%;
  height: 180px;
}

.form-footer-bg-image {
  position: absolute;
  left: -1.5rem;
  right: -1.5rem;
  bottom: -1.5rem;
  width: calc(100% + 3rem);
  height: 100%;
  background-image: url('assets/images/heartbeat-bottom-v1.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.form-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('assets/images/heartbeat-bottom-v1.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: visible !important;
  min-height: 150px;
  margin: -24px;

  & > button {
    margin-top: 40px;
  }
}

.delete-button {

  position:absolute;
  right: 0;
  padding-right: 10px;

  button {
    border: none;
    background: none;
  }
}

.centered-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-top: 2rem;
}

.title-icon {
  width: 24px;
  height: 30px;
}

.title-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $font-size-xl;
  font-weight: 500;
  color: var(--main-text-color);

  & > button {
    height: 36px;
    width: 20%;
    border: none;
  }
}

.title-divider {
  display: flex;
  border-bottom: 4px solid var(--sidebar-hover-icon-background);
}

.export-svg {
  width: 21px;
  height: 21px;
}

.cardio-dialog-backdrop {
  background: linear-gradient(
    125deg,
    #1e079f 25%,
    #2c0daa 25%,
    #2039cc 55%,
    #2a49d4 55%,
    #2a4ad5 65%,
    #2259df 65%,
    #0b5ce4 75%,
    #07c8f9 100%
  ) !important;
}

.cardio-dialog-panel {
  height: calc(100vh - 140px);

  &.calendar-dialog {
    height: 100vh;
  }

  .mat-mdc-dialog-container {
    border-radius: 16px;
    max-height: unset;
  }

  .mat-mdc-dialog-surface,
  .mdc-dialog__container {
    border-radius: 16px !important;
  }

  .dialog-close {
    position: fixed;
    top: 32px;
    right: 32px;
    background: transparent;
    border: none;
  }

  &:not(.cardio-add-panel) {
    .mat-mdc-dialog-container {
      border: 22px solid transparent;
      background: linear-gradient(180deg, #65d7f7 0%, #ffd43e 100%) border-box;
    }

    .side-button-wrapper {
      position: fixed;
      display: flex;
      top: 187px;
      gap: 32px;
      flex-direction: column;
      left: 45px;
      color: #fff;
      align-items: flex-end;
      &-jour {
        left: 0;
      }
      div {
        text-align: right;
      }

      .side-btn {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        align-items: flex-end;
        cursor: pointer;

        &:hover .numb {
          border-color: #09c6f9;
        }
      }

      .numb {
        width: 24px;
        height: 24px;
        text-align: center;
        border-radius: 50%;
        border: 2px solid #fff;
        margin-bottom: 2px;
      }
    }
  }

  &.cardio-add-panel {
    .side-button-wrapper {
      display: none;
    }
  }
}

.cardio-dialog-panel-no-border {
  border-radius: 16px;
  overflow: hidden;

  .mat-mdc-dialog-container {
    max-height: unset;
  }

  .dialog-close {
    position: fixed;
    top: 32px;
    right: 32px;
    background: transparent;
    border: none;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none;
  }

  @media (max-width: 600px) {
    max-width: 100vw !important;
    border-radius: 0;

    .dialog-close {
      position: fixed;
      top: 32px;
      right: 32px;
      background: transparent;
      z-index: 1000;
    }
  }
}

mat-dialog-container {
  --mat-dialog-transition-duration: 0s !important;
}

.cardio-dialog-panel-light {
  .mat-mdc-dialog-container {
    border-radius: 16px;
  }

  .mat-mdc-dialog-surface,
  .mdc-dialog__container {
    border-radius: 16px !important;
  }

  .dialog-close {
    position: fixed;
    top: 32px;
    right: 32px;
    background: transparent;
    border: none;
  }

  &:not(.cardio-add-panel) {
    .mat-mdc-dialog-container {
      border: 22px solid transparent;
      background: linear-gradient(180deg, #65d7f7 0%, #ffd43e 100%) border-box;
    }

    .side-button-wrapper {
      position: fixed;
      display: flex;
      top: 187px;
      gap: 32px;
      flex-direction: column;
      left: 45px;
      color: #fff;
      align-items: flex-end;

      div {
        text-align: right;
      }

      .side-btn {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        align-items: flex-end;
        cursor: pointer;

        &:hover .numb {
          border-color: #09c6f9;
        }
      }

      .numb {
        width: 24px;
        height: 24px;
        text-align: center;
        border-radius: 50%;
        border: 2px solid #fff;
        margin-bottom: 2px;
      }
    }
  }

  &.cardio-add-panel {
    .side-button-wrapper {
      display: none;
    }
  }
}

.cardio-offset-panel {
  margin-left: 50px;
}

.search {
  display: flex;
  margin-right: 1rem;

  .search-select {
    width: 142px;

    mat-form-field {
      width: 98%;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: #f8f9fb !important;
    }
  }

  .search-input {
    .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }

    button {
      background-color: #ebeff2;
      border: none;
      height: 36px;
    }
  }
}

.section-number {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--primary-blue-color);
  color: #fff;
  font-size: $font-size-base;
  font-weight: bold;
  text-align: center;
  line-height: 48px;
  margin-right: 16px;
  margin-top: 4px;
}

.section-header {
  font-size: $font-size-large;
  font-weight: 600;
  margin: 0;
  color: var(--main-text-color);
}

.mobile-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 6px;
  width: 100%;
}

.jelly-bean {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('assets/images/jelly_bean.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: visible !important;
  z-index: 1;
  margin-top: -2.5em;
  margin-left: -0.3125em;
  width: 21.875rem;
}

.mobile-preview {
  width: 220px;
  height: 465px;
  border-radius: 16px;
  overflow: auto;
  position: relative;
  background: transparent 0% 0% no-repeat padding-box;
  border: 3px solid #272d36;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -30px;
  overflow: hidden;

  .scrollable-view {
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  .mobile-footer {
    margin-top: auto;
    height: 30px;

    > svg {
      width: 100%;
      height: auto;
    }
  }

  .top-mobile-header {
    opacity: 0.65;
  }

  .logo {
    width: 100%;
    border-radius: 10px;
    text-align: center;
  }

  .center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 3px;
  }

  .logo-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-image {
    width: 45px;
    height: 45px;
  }

  .left-box {
    position: relative;
    left: 0;
    background-color: #4a4b4c;
    border-radius: 0 8px 8px 0;
    height: 100%;
    width: 30px;
    margin-right: 0.625em;
  }
  .right-box {
    position: relative;
    right: 0;
    background-color: #4a4b4c;
    border-radius: 8px 0 0 8px;
    height: 100%;
    width: 30px;
    margin-left: 0.625em;
  }

  .upgrade {
    background-color: var(--primary-color) !important;
    border: 2px solid var(--primary-color) !important;
    color: #000 !important;
  }

  .mobile-heading {
    font-size: $font-size-xxs;
    font-weight: 800;
    padding-left: 20px;
  }

  .mobile-desc {
    display: block;
    font-size: $font-size-xxs;
    padding-left: 20px;
    margin-top: -5px;
  }

  .services {
    // background-color: #4a4b4c;
    color: #000;
    padding-left: 0.625em;
    border-radius: 10px;
    width: 8.75em;
    min-height: 80px;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: content-box !important;
    border: 3px solid #272d36;

    &.upgrade {
      min-height: 100px;
    }
  }

  .service-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .service-title {
    display: block;
    color: var(--primary-color);
    font-weight: 800;
    text-transform: uppercase;
    font-size: $font-size-xxs;
    padding-top: 10px;
  }

  .service {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #000;
    padding: 0;
    line-height: 2;

    mat-icon {
      color: var(--primary-color);
      font-size: 12px;
      width: 14px;
      height: 14px;
      justify-content: center;
      align-items: center;
    }

    span {
      font-size: 8px;
    }
  }

  .plan-price {
    margin: 5px 0 5px 0;
    font-size: 1em;
    font-weight: 800;
    color: var(--primary-color);

    .upgrade {
      color: #000 !important;
    }

    span {
      font-size: 10px;
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 24px !important;
}

.footer-image.fixed {
  bottom: -120px;
}

.footer-wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  top: 120px;
}

.section-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 36px;
  padding-bottom: 24px;

  &:not(:nth-of-type(1)) {
    padding-top: 80px;
  }
}

.dialog-subtitle {
  color: #0a30a6;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

.circle-numb {
  background-color: #0a30a6;
  color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.circle-letter {
  background-color: #f9b831;
  color: #272d36;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.global-roles-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-image {
  position: absolute;
  @media (max-width: 1280px) {
    display: none;
  }
  left: 0;
  bottom: 0px;
  width: 100%;
  display: flex;
  z-index: 1;
}

.footer-image-padding {
  margin-top: 90px;
  position: relative;
  justify-content: center;
  z-index: 2;
}

.dialog-wrapper {
  padding: 30px;
  position: relative;
  height: 100%;
}

.dialog-heading {
  font-weight: 800 !important;
  font-size: 36px !important;
  color: #1d0794 !important;
  text-transform: uppercase;
  margin-bottom: 2rem !important;
}

.cust-section-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 24px;
}

// .table-wrapper {
//   padding: 0 10px;
// }

.mat-mdc-table .mdc-data-table__row {
  height: 48px !important;
}

.split-title {
  color: #000000 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
}

.cta-btn {
  border: #000000 2px solid;
  border-radius: 25px;
  padding: 11px 24px;
  background-color: #f9b831;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}

.sample-btn {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  background-color: #0d41e1;
  gap: 6px;
  border: 4px solid #0d41e1;
  color: #fff;
  padding: 13px 23px;
  width: 100%;
  justify-content: center;
  border-radius: 52px;
  align-items: center;
  transition: all ease-in 0.2s;
  font-weight: 500;
  &-xs {
    width: unset;
    padding: 13px 45px;
  }
  &-xxs {
    font-size: 14px;
    padding: 9px 26px;
    width: unset;
    top: -7px;
    position: relative;
    height: 40px;
  }
  &-footer {
    position: relative;
    z-index: 2;
  }
  &:hover {
    border: 4px solid #09c6f9;
  }
  &:disabled {
    color: #4f4f4f;
    background: #ebeff2;
    border: 4px solid #ebeff2;
  }
}

.jour-field {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.jour-label {
  font-size: 16px;
  color: #272d36;
  font-weight: 600;
}

.custom-radio-fieldset {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  &-2 {
    justify-content: flex-start;
    display: flex;
    margin-top: 24px;
    gap: 24px;
  }
  .btn-container {
    margin-left: auto;
    margin-top: 20px;
  }
  legend {
    padding-bottom: 10px;
  }
  .logo {
    width: 123px;
    height: 76px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      object-fit: contain;
    }
    &[aria-disabled='true'] {
      &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ebeff2;
      }
    }
  }
  label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
    /* Accessible outline */
    /* Remove comment to use */
    /*
      &:focus-within {
          outline: .125em solid $primary-color;
      }
    */
    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        &:before {
          box-shadow: inset 0 0 0 4px #0d41e1;
          border: 1px solid #dadfec;
          border-radius: 50%;
        }
      }
    }
    span {
      display: flex;
      align-items: center;
      border-radius: 99em; // or something higher...
      transition: 0.25s ease;
      color: #272d36;
      font-size: 14px;
      font-weight: 400;

      &:before {
        display: flex;
        flex-shrink: 0;
        content: '';
        background-color: #fff;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em #dadfec;
      }
    }
  }
}

.communications-btn {
  display: flex;
  justify-content: space-between;
  background-color: #dadfec33;
  border: 1px solid #dadfec;
  border-radius: 20px;
  color: #000000;
  font-size: 14px;
  align-items: center;
  padding: 9px 16px;
  width: 220px;
  outline: none;
  cursor: pointer;
  transition: all ease-in 0.2s;
  .mat-icon {
    color: #dadfec33;
    background-color: #fff;
    border: 1px solid #dadfec33;
    font-size: 16px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    transition: all ease-in 0.2s;
    align-items: center;
  }
  &-xs {
    width: unset;
  }

  .white-icon {
    display: none;
  }

  .blue-icon {
    display: flex;
  }

  &:hover {
    background-color: #bacbff;
    border: 1px solid #bacbff;
    color: #ffffff;

    .white-icon {
      display: flex;
    }

    .blue-icon {
      display: none;
    }
    .mat-icon {
      color: #ff1a83;
      background-color: #fff;
    }
  }

  &.active {
    background-color: #0a30a6 !important;
    border: 1px solid #0a30a6 !important;
    color: #ffffff;

    .white-icon {
      display: flex;
    }

    .blue-icon {
      display: none;
    }
    .mat-icon {
      color: #ff1a83;
      background-color: #fff;
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  .white-icon,
  .blue-icon {
    padding-right: 8px;
  }
}

.tip-img-type {
  border: 1px solid var(--primary-cta-color);
  color: var(--primary-cta-color);
  margin-right: 10px;
  padding: 0 10px 0 10px;
}

.mdc-snackbar {
  align-items: flex-end !important;
  border: 8px solid #09c6f9;
  border-radius: 16px;
}

.mat-mdc-snack-bar-container {
  // background: url('./assets/images/snackbarBg.svg') no-repeat !important;
  // height: 150px !important;
  --mdc-snackbar-container-shape: 10px !important;
  background: #fff !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: var(--main-text-color) !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background: #fff !important;
}
.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #09c6f9 !important;
}

.table-row {
  display: table-row;
}

.mat-bottom-sheet-container {
  border-radius: 16px !important;
}

.custom-spinner circle {
  stroke: #fff !important;
}

.sms-message-header,
.email-message-subject {
  position: relative;
  display: block;
  margin-left: auto;
  padding-right: 10px;
  padding-bottom: 10px;
}

.pull-message-header,
.email-message-header {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.email-quill {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

.updated-on {
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  margin: 0 0 0 auto;

  p {
    padding: 0;
    margin: 0 0 0 auto;

    &.sent-on {
      padding-right: 10px;
    }
  }
}

.error-message {
  font-size: 16px;
  color: #f44336;
  margin: 5px 0 0 0;
}

.mat-toolbar {
  background-color: transparent;
  color: var(--main-text-color);
}
