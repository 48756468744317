.mdc-button {
  padding: .75rem 1.5rem !important;

  &:not(.h-full) {
    height: unset !important;
  }

  &.field-height {
    height: 56px !important;
  }
}

.mdc-button__label {
  font-size: 1.125rem !important;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--primary-blue-color) !important;
  --mdc-filled-button-label-text-color: var(--primary-contrast-color) !important;
}

.actions-button {
  font-size: 1.125rem;
  padding: .75rem 1rem;
  background-color: var(--primary-cta-color);
  color: var(--primary-contrast-color);
  border: none;
  border-radius: 25px;
  font-weight: 500;
  transition: .2s ease-in-out;
  min-width: 170px;

  &.form-pop-out {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--sidebar-font-color);
    text-decoration: none;

    &:hover {
      background-color: #F5F7F8;
      color: var(--primary-blue-color);
    }
  }

  &:hover {
    background-color: #4F4E4E;
  }
}

.mdc-button {
  &.set-btn {
    background-color: #0D41E1 !important;
    height: 49px !important;
    border-radius: 25px;
    padding: 18px 38px !important;
    color: #fff !important;
    border: 4px solid #0D41E1;
    &:hover {
      background-color: #0A30A6;
      border: 4px solid #09C6F9;
      color: #ffffff;
    }
  }
}

.cta-button {
  height: 49px;
  width: 100%;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 500;

  &.module-header-btn {
    width: 20%;
  }

  &.w-150 {
    width: 150px;
  }

  &.w-200 {
    width: 200px;
  }

  &.primary {
    background: var(--primary-cta-color);
    color: var(--header-font-color);
    border: none !important;

    &:disabled {
      background-color: #cccccc; // Change background color for disabled state
      color: #666666; // Change text color for disabled state
      cursor: not-allowed; // Change cursor to indicate disabled state
    }
  }

  &.secondary {
    background: var(--unnamed-color-ffffff);
    background: #FFFFFF ;
    border: 2px solid #0D41E1;
    color: var(--sidebar-hover-background);
  }

  &.cancel {
    background: transparent;
    color: var(--primary-blue-color);
    border: none !important;

    &:hover {
      text-decoration: underline;
    }
  }
  &.primary:hover, &.secondary:hover{
      border: 4px solid var(--unnamed-color-09c6f9);
      background: #0A30A6;
      border: 4px solid #09C6F9 !important;
      color: #ffffff;

      &:disabled {
        background-color: #cccccc; // Change background color for disabled state
        color: #666666; // Change text color for disabled state
        cursor: not-allowed; // Change cursor to indicate disabled state
      }
  }
}

