
/**** Other imports ****/
// Font families
$font-family-outfit: 'Outfit', sans-serif;

// Font sizes
$font-size-base: 1rem; // 16px
$font-size-xxs: 0.75em; // 12px
$font-size-xs: 0.8125em; // 13px
$font-size-small: 0.875em; // 14px
$font-size-large: 1.25em; // 20px
$font-size-xl: 1.8em;  // 24px
$font-size-xxl: 2.25em; // 36px
$font-size-xxxl: 2.5em; // 40px

$font-size-form-label: 1.125em; // 18px

// Line heights
$line-height-base: 1.5;
$line-height-small: 1.25;
$line-height-large: 1.75;

// Font weights
$font-weight-normal: 500;
$font-weight-medium: 600;
$font-weight-bold: 700;

// Headings
$h1-font-size: $font-size-large;
$h2-font-size: $font-size-large;
$h3-font-size: $font-size-large;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-outfit;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
  margin-top: 0;
  margin-bottom: 1rem;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}
