.mat-stepper-vertical, .mat-stepper-horizontal {
  background-color: transparent !important;
}

.mat-horizontal-stepper-header::before, .mat-horizontal-stepper-header::after {
  border-top-color: var(--disabled-color) !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
  &[tabindex="0"] {
    position: relative;
    overflow: visible !important;
  }
}

.mat-horizontal-stepper-header-container {
  border-bottom: 1px solid var(--disabled-color);
}

.mat-horizontal-content-container {
  padding: 1.5rem 0 !important;
}

.mat-stepper-horizontal-line {
  border-top-color: var(--disabled-color) !important;
}

.mat-step-label-active.mat-step-label-selected {
  position: relative;
  overflow: visible !important;

  &::after {
    content: '';
    position: absolute;
    top: calc(calc(100% + calc((var(--mat-stepper-header-height) - 24px) / 2)) - 4px);
    left: 0;
    width: 100%;
    border-bottom: 4px solid var(--primary-blue-color);
  }

  .mat-step-text-label {
    opacity: 1;
  }
}

.mat-step-text-label {
  font-size: 1.125rem;
  opacity: .75;
  color: var(--card-bg-contrast) !important;
}

.mat-step-icon {
  font-weight: bold;
  background-color: transparent !important;
  border-width: 2px;
  border-style: solid;

  &.mat-step-icon-state-number:not(.mat-step-icon-selected) {
    border-color: var(--disabled-color) !important;
    color: var(--disabled-color) !important;
  }
}

.mat-step-icon-selected, .mat-step-icon-state-edit, .mat-step-icon-state-done {
  border-color: (--primary-blue-color) !important;
  color: var(--primary-blue-color) !important;
}

